section.login_form {
  display: none;
}

section.button_container {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (min-width: 500px) {
  section.login_form {
    display: inline-block;
  }

  section.button_container {
    flex: 1;
    justify-content: unset;
  }
}
