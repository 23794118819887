:root {
  --color-background: #f0f0f7;
  --color-background-dark: #02001c;
  --color-background-dashboard: rgb(158, 195, 255, 0);
  --color-background-drawer: '#A0C4FF';
  --color-primary-lighter: #ffefef;
  --color-primary-light: #ffacac;
  --color-primary: #ffadad;
  --color-primary-dark: #ad8089;
  --color-primary-darker: #6e5b6b;
  --color-secondary: #fdffb6;
  --color-secondary-dark: #04bf58;
  --color-title-in-primary: #ffffff;
  --color-text-in-primary: #d4c2ff;
  --color-text-complement: #9c98a6;
  --color-text-base: #000;
  --color-line-in-white: #e6e6f0;
  --color-input-background: #f8f8fc;
  --color-button-text: #464353;
  --color-box-base: #ffffff;
  --color-box-footer: #fafafc;
  --color-input-background: #ffffff17;

  /* font-size: 60%; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-dark) transparent;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 90%;
  max-width: 700px;
}

a {
  text-decoration: none;
}

button[class*='MuiButton-containedSizeLarge'] {
  font-size: 1.2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (min-width: 500px) {
  html,
  body,
  #root {
    height: 100%;
  }
}
