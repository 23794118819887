form .multiple_input_container {
  background-color: var(--color-input-background);
  border-radius: 10px;
  padding: 5%;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin: 3% 0;
}

.multiple_input_container .icon_section svg {
  height: 100%;
  margin: auto 0;
  /* display: block; */
  /* font-size: 5rem; */
}

.multiple_input_container .input_section {
  flex: 1.5;
}

.multiple_input_container .input_label {
  margin-left: 5%;
}
