form.login_form {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
}

@media (min-width: 500px) {
  form .form_button {
    width: 100%;
  }
}
