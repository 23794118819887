main.login_container {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, var(--color-primary) 0%, var(--color-background-dark) 100%);

  padding: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

main .mobile_dashboard {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}

main .mobile_dashboard .logo {
  max-width: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3%;
}

main .mobile_dashboard .main_image {
  max-width: 700px;
  padding: 5%;
}

main section.text_container {
  text-align: center;
  flex: 1;
}

main section.text_container h1 {
  margin-bottom: 5%;
}

@media (min-width: 500px) {
  main.login_container {
    flex-direction: row;
    justify-content: space-around;
  }

  main .mobile_dashboard {
    max-width: 50%;
  }

  main .info_section {
    max-width: 40%;
  }

  main .mobile_dashboard .main_image {
    padding: unset;
  }

  main .mobile_dashboard .logo {
    max-width: 3.5rem;
    margin: 1%;
  }
}
