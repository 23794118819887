.btn {
  text-align: center;
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
  margin: 2% 0;
  font: bold 1.25rem Archivo;
  border: none;
  color: var(--color-button-text);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  text-transform: none;
  color: #464353;
}

.btn:hover {
  opacity: 0.8;
}

.btn_primary {
  background-color: var(--color-primary);
}

.btn_secondary {
  background-color: var(--color-secondary);
}
